.color--blue8 {
  color: var(--color-blue8);
}
.color--blue6 {
  color: var(--color-blue6);
}
.color--blue4 {
  color: var(--color-blue4);
}
.color--blue2 {
  color: var(--color-blue2);
}

.color--orange10 {
  color: var(--color-orange10);
}
.color--orange8 {
  color: var(--color-orange8);
}
.color--orange6 {
  color: var(--color-orange6);
}
.color--orange4 {
  color: var(--color-orange4);
}
.color--orange2 {
  color: var(--color-orange2);
}

.color--neutral8 {
  color: var(--color-neutral8);
}
.color--neutral6 {
  color: var(--color-neutral6);
}
.color--neutral4 {
  color: var(--color-neutral4);
}
.color--neutral2 {
  color: var(--color-neutral2);
}

.color--black {
  color: var(--color-black);
}
.color--white {
  color: var(--color-white);
}

.bgcolor--blue8 {
  background-color: var(--color-blue8);
}
.bgcolor--blue6 {
  background-color: var(--color-blue6);
}
.bgcolor--blue4 {
  background-color: var(--color-blue4);
}
.bgcolor--blue2 {
  background-color: var(--color-blue2);
}

.bgcolor--orange10 {
  background-color: var(--color-orange10);
}
.bgcolor--orange8 {
  background-color: var(--color-orange8);
}
.bgcolor--orange6 {
  background-color: var(--color-orange6);
}
.bgcolor--orange4 {
  background-color: var(--color-orange4);
}
.bgcolor--orange2 {
  background-color: var(--color-orange2);
}

.bgcolor--neutral8 {
  background-color: var(--color-neutral8);
}
.bgcolor--neutral6 {
  background-color: var(--color-neutral6);
}
.bgcolor--neutral4 {
  background-color: var(--color-neutral4);
}
.bgcolor--neutral2 {
  background-color: var(--color-neutral2);
}

.bgcolor--black {
  background-color: var(--color-black);
}
.bgcolor--white {
  background-color: var(--color-white);
}
