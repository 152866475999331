.social-icons {
  display: flex;
  gap: 16px;
  height: 30px;
}
.social-icons > a {
  border: 0;
}
.social-icons > a > img {
  object-fit: contain;
  height: 30px;
}
