.section {
  width: calc(100vw - 48px);
  display: grid;
  justify-items: center;
  align-content: center;

  background-color: white;
  padding: 24px;
}

.CTA {
  background-color: var(--color-blue8);
  color: var(--color-white);
  position: sticky;
  top: 0;
}

.hero {
  padding: 64px 24px 80px 24px;
  background-color: var(--color-neutral4);
}

.tiles {
  padding: 16px 24px;
}

.footer {
  background-color: var(--color-blue8);
  color: var(--color-white);
}
@media (max-width: 840px) {
  .hero {
    padding: 32px 24px 40px 24px;
  }
}
