.grid {
  width: 100%;
  display: grid;
  gap: 16px;
  align-items: start;
}

.grid__fullplus {
  width: 100%;
  max-width: 1036px;
  margin: 0 100px;
  grid-template-columns: 100px repeat(6, 1fr);
}

.grid__fullplus > * {
  grid-column: 2 / span 6;
}

.grid__full--subgrid {
  grid-template-columns: repeat(6, 1fr);
}

.grid__two-thirds {
  grid-column: span 4;
}

.grid__two-thirds--subgrid {
  grid-column: span 4;
  grid-template-columns: repeat(4, 1fr);
}

.grid__half {
  grid-column: span 3;
}

.grid__half--subgrid {
  grid-column: span 3;
  grid-template-columns: repeat(3, 1fr);
}

.grid__one-third {
  grid-column: span 2;
}

.grid__one-third--subgrid {
  grid-column: span 2;
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 840px) {
  .grid__fullplus {
    grid-template-columns: 32px repeat(3, 1fr);
  }

  .grid__fullplus {
    grid-column: 2 / span 3;
  }
  .grid__full--subgrid {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid__two-thirds {
    grid-column: span 3;
  }
  .grid__two-thirds--subgrid {
    grid-column: span 3;
    grid-template-columns: repeat(3, 1fr);
  }
}
